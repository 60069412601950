import { BudgetRangeSelect } from './components/BudgetRangeSelect/BudgetRangeSelect.js';
import { getInputTypeFromBudget } from './components/BudgetRangeSelect/utilities.js';
import {
  JobPostingSelect,
  type JobPostingSelectOption,
} from './components/JobPostingSelect/JobPostingSelect.js';
import {
  PROJECT_DURATION_OPTIONS,
  ProjectDurationSelect,
} from './components/ProjectDurationSelect/ProjectDurationSelect.js';
import {
  AvatarContainer,
  BackButton,
  ButtonContainer,
  FormV2,
  Header,
  HeaderSubtitle,
  HeaderText,
  HeaderTitle,
  Row,
} from './InquiryForm.styles.js';
import { InquiryFormV2Data } from './types.js';
import { Avatar } from '@/components/Avatar/Avatar.js';
import { FormField } from '@/components/FormField/FormField.js';
import { PlatformButton } from '@/components/PlatformButton/PlatformButton.js';
import { TextField } from '@/components/TextField/TextField.js';
import { useCurrentUser } from '@/hooks/useCurrentUser.js';
import { useTrackFormProgress } from '@/hooks/useTrackFormProgress.js';
import { useUserProfile } from '@/hooks/useUserProfile.js';
import {
  type InquiryFormSection,
  type PropertyLibrary,
} from '@/services/analytics/propertyLibrary.js';
import { styled } from '@/stitches/index.js';
import { useMount } from '@contra/react-hooks/useMount';
import { ChevronLeftIcon } from '@contra/ui-kit/icons';
import { FormEvent, Suspense, useCallback, useRef, useState } from 'react';
import { type UseFormReturn } from 'react-hook-form';

const MessageContainer = styled('div', {
  borderColor: '$gray40',
  borderRadius: '$8',
  borderStyle: 'solid',
  borderWidth: '$1',
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
  marginBottom: '$24',
  padding: '$12',
  width: '100%',
});

const MessageLabelAndFieldWrapper = styled('div', {
  '& input, & input:focus': {
    paddingLeft: 0,
  },
  '& textarea, & textarea:focus': {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingTop: 0,
  },
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  width: '100%',
});

const MessageLabel = styled('label', {
  color: '$gray60',
  fontSize: '$12',
  fontWeight: 400,
  lineHeight: '$16',
  textAlign: 'left',
});

type InquiryFormV2Props = {
  readonly form: UseFormReturn<InquiryFormV2Data>;
  readonly hasActiveJobPostings: boolean;
  readonly isSubmitting: boolean;
  readonly onClose: (cta: PropertyLibrary['cta'] | null) => void;
  readonly onSubmit: (data: InquiryFormV2Data) => void;
  readonly setStep: (step: 0 | 1) => void;
  readonly step: 0 | 1;
};

const getInquiryInputValuesByJobPosting = (
  jobPosting: JobPostingSelectOption,
) => {
  const feeMax = jobPosting.budget?.feeMax ?? undefined;
  const feeMin = jobPosting.budget?.feeMin ?? undefined;
  const type = jobPosting.budget?.type ?? undefined;

  const rate = getInputTypeFromBudget({
    feeMax,
    feeMin,
    type,
  });

  const duration = PROJECT_DURATION_OPTIONS.find(
    ({ max, min }) =>
      min === jobPosting?.minDuration && max === jobPosting?.maxDuration,
  );

  return {
    budget: rate?.title,
    duration: duration?.title,
  };
};

// eslint-disable-next-line complexity
export const InquiryFormV2 = ({
  form,
  hasActiveJobPostings,
  isSubmitting,
  onClose,
  onSubmit,
  setStep,
  step,
}: InquiryFormV2Props) => {
  const currentUser = useCurrentUser();
  const userProfile = useUserProfile();
  const trackFormProgress = useTrackFormProgress<InquiryFormSection>({
    formName: 'ip_inquiry',
  });
  const [key, setKey] = useState('');
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger,
  } = form;
  const isInitialValidationComplete = useRef(false);

  const onFormChange = useCallback(() => {
    const {
      budget,
      duration,
      emailAddress,
      firstName,
      lastName,
      message,
      messageGreeting,
      title,
    } = getValues();

    trackFormProgress({
      inquiry_details: [budget, duration, title],
      personal_details: [firstName, lastName, emailAddress],
      write_a_message: [message.length > 3, messageGreeting],
    });

    trigger();
  }, [getValues, trackFormProgress, trigger]);

  const updateSelectedPosting = useCallback(
    (posting: JobPostingSelectOption | null) => {
      if (!posting) {
        setValue('jobPostingId', '');
        setValue('title', '');
        setValue('duration', undefined);
        setValue('budget', undefined);
        setKey('');
        return;
      }

      const { budget, duration } = getInquiryInputValuesByJobPosting(posting);
      setValue('duration', duration ?? '');
      setValue('budget', budget ?? '');
      setValue('jobPostingId', posting.id);
      setKey(posting.id);
    },
    [setValue],
  );

  const isFirstStepValid = !(
    errors.firstName ||
    errors.lastName ||
    errors.emailAddress ||
    errors.messageGreeting ||
    errors.message
  );

  const isSecondStepValid = !(errors.title || errors.duration || errors.budget);

  const onFirstStepSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!isFirstStepValid) {
        return;
      }

      setStep(1);
    },
    [isFirstStepValid, setStep],
  );

  useMount(() => {
    isInitialValidationComplete.current = true;
    trigger();
  });

  const currentDuration = PROJECT_DURATION_OPTIONS.find(
    ({ title }) => title === getValues().duration,
  );

  const isLinkedToJobPosting = Boolean(getValues().jobPostingId);
  const isDisabled =
    userProfile?.visitorCanEdit ||
    (step === 0 && !isFirstStepValid) ||
    (step === 1 && !isSecondStepValid);

  return (
    <FormV2
      onChange={onFormChange}
      onSubmit={step === 0 ? onFirstStepSubmit : handleSubmit(onSubmit)}
    >
      <Header>
        {userProfile?.avatarImage?.uid ? (
          <AvatarContainer>
            <Avatar
              height={56}
              rounded
              uid={userProfile.avatarImage.uid}
              width={56}
            />
          </AvatarContainer>
        ) : null}
        <HeaderText>
          <HeaderTitle>
            Request to work with {userProfile?.firstName}{' '}
            {userProfile?.lastName}
          </HeaderTitle>
          <HeaderSubtitle>
            {userProfile?.firstName} will receive your message on Contra
          </HeaderSubtitle>
        </HeaderText>
      </Header>
      {step === 0 ? (
        <>
          {currentUser?.userAccount ? null : (
            <>
              <Row>
                <FormField>
                  <TextField variant="new">
                    <TextField.Label htmlFor="firstName">
                      First Name
                    </TextField.Label>
                    <TextField.InputGroup>
                      <TextField.Input
                        autoComplete="given-name"
                        autoFocus
                        defaultValue={
                          currentUser?.userAccount?.profile.firstName ??
                          undefined
                        }
                        id="firstName"
                        {...register('firstName')}
                      />
                    </TextField.InputGroup>
                  </TextField>
                </FormField>
                <FormField>
                  <TextField variant="new">
                    <TextField.Label htmlFor="lastName">
                      Last Name
                    </TextField.Label>
                    <TextField.InputGroup>
                      <TextField.Input
                        autoComplete="family-name"
                        defaultValue={
                          currentUser?.userAccount?.profile.lastName ??
                          undefined
                        }
                        id="lastName"
                        {...register('lastName')}
                      />
                    </TextField.InputGroup>
                  </TextField>
                </FormField>
              </Row>
              <FormField>
                <TextField variant="new">
                  <TextField.Label htmlFor="emailAddress">
                    Email
                  </TextField.Label>
                  <TextField.InputGroup>
                    <TextField.Input
                      defaultValue={
                        currentUser?.userAccount?.emailAddress ?? undefined
                      }
                      id="emailAddress"
                      {...register('emailAddress')}
                      type="email"
                    />
                  </TextField.InputGroup>
                </TextField>
              </FormField>
            </>
          )}

          <MessageContainer>
            <MessageLabelAndFieldWrapper>
              <MessageLabel htmlFor="messageGreeting">Greeting</MessageLabel>
              <TextField.Input
                defaultValue={`Hi ${userProfile?.firstName}!`}
                id="messageGreeting"
                {...register('messageGreeting')}
              />
            </MessageLabelAndFieldWrapper>

            <MessageLabelAndFieldWrapper>
              <MessageLabel htmlFor="message">Personal message</MessageLabel>
              <TextField.TextArea
                aria-label="message"
                autoFocus={Boolean(currentUser?.userAccount)}
                {...register('message')}
                css={{
                  resize: 'none',
                }}
                id="message"
                placeholder={`Start a conversation with ${userProfile?.firstName}...`}
                rows={7}
              />
            </MessageLabelAndFieldWrapper>
          </MessageContainer>
        </>
      ) : (
        <>
          <Suspense
            fallback={
              <JobPostingSelect
                disabled
                onSelection={() => {}}
                outlined
              />
            }
          >
            {hasActiveJobPostings ? (
              <Row>
                <FormField>
                  <JobPostingSelect
                    onSelection={updateSelectedPosting}
                    outlined
                  />
                </FormField>
              </Row>
            ) : null}
          </Suspense>

          <Row>
            <FormField>
              <TextField variant="new">
                <TextField.Label htmlFor="title">
                  Opportunity name
                </TextField.Label>
                <TextField.InputGroup>
                  <TextField.Input
                    autoFocus
                    id="title"
                    {...register('title')}
                    placeholder="eg. Product launch video production"
                  />
                </TextField.InputGroup>
              </TextField>
            </FormField>
          </Row>
          <Row>
            <FormField>
              <ProjectDurationSelect
                disabled={isLinkedToJobPosting}
                initialSelectedItem={currentDuration}
                key={key}
                onSelection={(value) => {
                  setValue('duration', value.id);
                  trigger();
                }}
                variant="new"
              />
            </FormField>
          </Row>
          <Row>
            <FormField>
              <BudgetRangeSelect
                disabled={isLinkedToJobPosting}
                initialSelection={getValues().budget ?? undefined}
                key={key}
                onSelection={(value) => {
                  setValue('budget', value.id);
                  trigger();
                }}
                variant="new"
              />
            </FormField>
          </Row>
        </>
      )}

      <ButtonContainer>
        {step === 0 ? (
          <PlatformButton
            onClick={() => onClose('secondary')}
            type="button"
            variant="secondary"
          >
            Cancel
          </PlatformButton>
        ) : (
          <BackButton
            css={{
              '@bp1': {
                width: '$48',
              },
            }}
            onClick={() => setStep(0)}
            type="button"
          >
            <span>Back</span>
            <ChevronLeftIcon />
          </BackButton>
        )}

        <PlatformButton
          disabled={
            isSubmitting || isDisabled || !isInitialValidationComplete.current
          }
          isLoading={isSubmitting}
          variant="primary"
        >
          Next
        </PlatformButton>
      </ButtonContainer>
    </FormV2>
  );
};
