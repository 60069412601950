/* eslint-disable n/no-process-env */
const isDevelopment = process.env.NODE_ENV === 'development';
const isTestEnvironment = process.env.NODE_ENV === 'test';
const isReviewApp = process.env.CCP_IS_REVIEW_APP === 'true';
const webAppUrl = process.env.CCP_WEB_APP_URL;

export const getContraWebAppBaseUrl = () => {
  // if its development, return the localhost url
  if (isDevelopment) {
    return 'https://local.contra.dev:8080';
  }

  if (isTestEnvironment && !isReviewApp) {
    return 'https://local.contra.dev:8080';
  }

  // if its review apps, return the review apps url from the config otherwise fall back to local to prevent accidental prod exposure
  if (isReviewApp) {
    return webAppUrl || 'https://local.contra.dev:8080';
  }

  // if its production, return the production url
  return 'https://contra.com';
};
