import {
  IconContainer,
  Optional,
  SectionContainer,
  Title,
} from './FormSection.styles.js';
import { Tooltip } from '@/components/Tooltip/Tooltip.js';
import { InfoIcon } from '@contra/ui-kit/icons';
import { type ReactNode } from 'react';

type FormSectionProps = {
  readonly optional?: boolean;
  readonly title: string;
  readonly tooltip?: ReactNode;
};

export const FormSection = ({
  optional = false,
  title,
  tooltip,
}: FormSectionProps) => {
  return (
    <SectionContainer>
      <span>
        <Title>{title}</Title>
        {optional ? <Optional> (Optional)</Optional> : null}
      </span>

      {tooltip && (
        <IconContainer>
          <Tooltip
            content={tooltip}
            side="bottom"
            sideOffset={16}
          >
            <InfoIcon
              role="img"
              size={16}
            />
          </Tooltip>
        </IconContainer>
      )}
    </SectionContainer>
  );
};
